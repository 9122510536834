<template>
  <v-navigation-drawer
    v-model="drawer"
    class="elevation-15 pulse-navigation"
    :mini-variant="mini"
    app
    width="192px"
    color="#08497e"
    mobile-breakpoint="961"
    style="z-index: 9;"
  >
    <v-list-item class="px-0 d-flex align-center justify-space-between">
      <v-list-item-content style="max-width: 200px; min-height: 60px;">
        <div
          v-if="!mini"
          class="desktop-logo"
        >
          <div class="normal">
            <img class="main-pulse" src="@/assets/icon.svg" height="34px">
            <img class="pulse" src="@/assets/pulse.svg" height="34px">
          </div>
          <img class="cloud-img" src="@/assets/eng-cloud.svg" height="34px">
        </div>
        <v-img
          v-else
          eager
          contain
          width="34"
          height="34px"
          src="@/assets/NavigationPane/logo.png"
        />
      </v-list-item-content>

      <v-list-item-action class="ml-9 px-0 hidden-md-and-up">
        <v-btn
          color="white"
          icon
          @click="$store.commit('toggleMenu')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-list dark dense class="hide-scroll">
      <div v-for="route in nav_items" :key="route.title">
        <MenuPlainItem
          v-if="!route.items && (route.canView == undefined || route.canView)"
          :icon="route.icon"
          :title="route.title"
          :path="route.path"
          :params="route.params"
        />
        <MenuDesktopCollapseItem
          v-if="route.items && !mini && (route.canView == undefined || route.canView)"
          :items="route.items"
          :icon="route.icon"
          :title="route.title"
        />
        <MenuMobileCollapseItem
          v-if="route.items && mini && (route.canView == undefined || route.canView)"
          :items="route.items"
          :icon="route.icon"
          :title="route.title"
        />
      </div>
    </v-list>

    <template #append>
      <div class="pa-2 hidden-xs-only">
        <v-btn class="float-right" color="white" icon @click="mini = !mini">
          <v-icon v-if="mini">
            mdi-chevron-double-right
          </v-icon>
          <v-icon v-else>
            mdi-chevron-double-left
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import MenuPlainItem from "@/sharedComponents/navigation/MenuPlainItem";
import MenuDesktopCollapseItem from "@/sharedComponents/navigation/MenuDesktopCollapseItem";
import MenuMobileCollapseItem from "@/sharedComponents/navigation/MenuMobileCollapseItem";
import {
  mdiCounter,
  mdiApplicationBracketsOutline,
  mdiArrowUpDownBoldOutline,
  mdiApplicationCogOutline,
  mdiDatabaseSearchOutline,
  mdiAccountMultipleCheck,
  mdiBell,
} from '@mdi/js';

export default {
  name: "Navigation",
  components: {
    MenuMobileCollapseItem,
    MenuDesktopCollapseItem,
    MenuPlainItem,
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      drawer: true,
      accounts_nav: [
        {
          title: "Account Selection",
          ico: "@/assets/icons/home.svg",
          icon: "$home",
          path: 'Accounts',
        },
      ],
      admin_accounts_nav: [
        {
          title: "Add Account",
          path: 'NewAccount',
          icon: 'mdi-domain',
        },
        {
          title: "Manage Users",
          path: 'ManagerUserList',
          icon: 'mdi-account-multiple',
        },
        {
          title: "Database Mgmt",
          path: 'ManagerDatabaseList',
          icon: 'mdi-database',
        },
        {
          title: "Global Deployments",
          path: 'GlobalDeployments',
          icon: 'mdi-calendar',
        },
      ],
      mini: false,
      subscription: "",
    };
  },
  computed: {
    hasReportingRole() {
      return this.$store.getters['user/isReporting'];
    },
    hasNoContactDataRole() {
      return this.$store.getters['user/hasNoContactDataRole'];
    },
    hasReadOnlyRole() {
      return this.$store.getters['user/isReadOnly'];
    },
    regular_nav() {
      return [
        {
          title: "Dashboard",
          ico: "@/assets/icons/home.svg",
          icon: "$home",
          items: [
            {
              title: "My Dashboard",
              path: "DashboardView",
              params: { id: this.$store.getters["user/defaultDashboard"] }
            },
            { title: "Dashboard List", path: "DashboardList" }
          ],
        },
        {
          title: "Google Product Suite",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginGoogleAnalytics"),
          icon: "$google",
          items: [
            {
              title: "Analytics",
              path: "GoogleAnalytics",
            },
            // {
            //   title: "Search Console",
            //   path: "GoogleSearch",
            // },
          ],
        },
        {
          title: "",
          id: "accountType",
          icon: "$contacts",
          items: [
            { title: "Search", path: "ContactsSearch", canView: this.hasReadOnlyRole },
            { title: "Segments", path: "SegmentsSearch" },
            {
              title: "Add / Import",
              path: "AddContact",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            {
              title: "Match Tool",
              path: "ContactMatchTool",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            { title: "Sources", path: 'Sources' },
          ],
          canView: this.hasReadOnlyRole,
        },
        {
          title: "Marketing",
          icon: "$rocket",
          items: [
            { title: "Campaigns", path: "CampaignsSearch" },
            { title: "Media Library", path: "MediaLibrary" , canView: this.hasNoContactDataRole},
            { title: "Email Creatives", path: "EmailCreatives" },
            { title: "From Lines", path: "FromLines" },
            {
              title: "Bulk Unsubscribe",
              path: "BulkUnsubscribe",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            { title: "Blacklist", path: "Blacklist", canView: this.hasReadOnlyRole },
          ],
          canView: this.hasNoContactDataRole,
        },
        {
          title: "Channels/Tactics",
          icon: "$messages",
          items: [
            { title: "Email", path: "EmailCreatives" },
            { title: "Banners", path: "Banners" },
            { title: "Alerts", path: "Alerts" },
            { title: "Direct Mail", path: "DirectMail" },
            { title: "Events/Conferences", path: "Events"},
            { title: "Forms", path: "Forms" },
          ],
          canView: this.hasNoContactDataRole,
        },
        {
          title: "Reports",
          icon: "$piechart",
          path: "Reports",
        },
        {
          title: "Actions",
          icon: mdiDatabaseSearchOutline,
          items: [
            { title: "Search", path: "SearchActions" },
            {
              title: "Import",
              path: "ImportActions",
              canView: this.$store.getters['user/isPulseAdmin'] || this.$store.getters['user/isPulseUser'],
            },
          ],
          canView: this.hasReadOnlyRole,
        },
        {
          title: "Lead Score",
          icon: mdiCounter,
          path: "LeadScoreRules",
          canView: this.hasNoContactDataRole
        },
        {
          title: "Web Tracking",
          icon: mdiApplicationBracketsOutline,
          path: 'WebTrackingScripts',
          canView: this.hasNoContactDataRole,
        },
        {
          title: "Imports/Exports",
          icon: mdiArrowUpDownBoldOutline,
          items: [
            {
              title: "Imports",
              path: "ImportsView",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            { title: "Exports", path: "ExportsView" }
          ],
          canView: this.hasReadOnlyRole,
        },
        {
          title: "Arrowhead",
          icon: "$arrowhead",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginArrowhead") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginArrowheadImport",
            },
          ],
        },
        {
          title: "BioPharm",
          icon: "$biopharm",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginBioPharm") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "BioPharmImport",
            },
          ],
        },
        {
          title: "Bulletin Healthcare",
          icon: "$bulletin",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginBulletinHealthcare") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "BulletinHealthcareImport",
            },
          ],
        },
        {
          title: "DCC",
          icon: "$dcc",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginDCC") && this.hasReadOnlyRole,
          items: [
            {
              title: "Import",
              path: "PluginDccImport",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            {
              title: "Export",
              path: "PluginDccExport",
            },
          ],
        },
        {
          title: "Doximity",
          icon: "$doximity",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginDoximity") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginDoximityImport",
            },
          ],
        },
        {
          title: "Epocrates",
          icon: "$epocrates",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginEpocrates") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginEpocratesImport",
            },
          ],
        },
        {
          title: "International Healthcare Media",
          icon: "$ihm",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginInternationalHealthcareMedia") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginInternationalHealthcareMediaImport",
            },
          ],
        },
        {
          title: "Jun Group",
          icon: "$junGroup",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginJunGroup") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginJunGroupImport",
            },
          ],
        },
        {
          title: "Lahlouh",
          icon: "$lahlouh",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginLahlouh") && this.hasReadOnlyRole,
          items: [
            {
              title: "Import",
              path: "PluginLahlouhImport",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            {
              title: "Export",
              path: "PluginLahlouhExport",
            },
          ],
        },
        {
          title: "McKesson",
          icon: "$mckesson",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginMcKesson") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginMcKessonImport",
            },
          ],
        },
        {
          title: "MDedge",
          icon: "$mdedge",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginMDedge") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "MDedgeImport",
            },
          ],
        },
        {
          title: "MDLinx",
          icon: "$mdlinx",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginMDLinx") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginMDLinxImport",
            },
          ],
        },
        {
          title: "MedPage Today",
          icon: "$medPageToday",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginMedPageToday") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginMedPageTodayImport",
            },
          ],
        },
        {
          title: "Medscape",
          icon: "$medscape",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginMedscape") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "MedscapeImport",
            },
          ],
        },
        {
          title: "MNG Health",
          icon: "$mngHealth",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginMngHealth") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginMngHealthImport",
            },
          ],
        },
        {
          title: "Notifications",
          icon: mdiBell,
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginNotification") && this.$store.getters['user/isPulseAdmin'],
          path: 'PluginNotificationView'
        },
        {
          title: "OptimizeRx",
          icon: "$optimizerx",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginOptimizeRx") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginOptimizeRxImport",
            },
          ],
        },
        {
          title: "Prescription Data",
          icon: "$prescriptionData",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginPrescriptionData"),
          items: [
            {
              title: "Import",
              path: "PluginPrescriptionDataImport",
              canView: this.hasNoContactDataRole,
            },
            {
              title: "Report",
              path: "PrescriptionReportView",
            },
          ],
        },
        {
          title: "PulsePoint HCP365",
          icon: "$pulsepointHcp365",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginPulsePointHCP365") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginPulsePointHCP365Import",
            },
          ],
        },
        {
          title: "PulsePoint Life",
          icon: "$pulsepointlife",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginPulsePointLife") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginPulsePointLifeImport",
            },
          ],
        },
        {
          title: "p-value",
          icon: "$pvalue",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginPvalue") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginPvalueImport",
            },
          ],
        },
        {
          title: "ReachMD",
          icon: "$reachmd",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginReachMD") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginReachMDImport",
            },
          ],
        },
        {
          title: "Rep Management",
          icon: mdiAccountMultipleCheck,
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginRepManagement"),
          items: [
            {
              title: "Import",
              path: "PluginRepManagementImport",
              canView: this.hasNoContactDataRole,
            },
            {
              title: "Export",
              path: "PluginRepManagementExport",
              canView: this.hasNoContactDataRole,
            },
            {
              title: "Report",
              path: "RepManagementReportView",
            },
          ],
        },
        {
          title: "RxNT",
          icon: "$rxnt",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginRxNT") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "RxNTImport",
            },
          ],
        },
        {
          title: "Sermo",
          icon: "$sermo",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginSermo") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "SermoImport",
            },
          ],
        },
        {
          title: "UBC - Alcon",
          icon: "$ubc",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginUBCAlcon") && this.hasReadOnlyRole,
          items: [
            {
              title: "Import",
              path: "PluginUbcAlconImport",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            {
              title: "Export",
              path: "PluginUbcAlconExport",
            },
          ],
        },
        {
          title: "VuMedi",
          icon: "$vumedi",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginVuMedi") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "PluginVuMediImport",
            },
          ],
        },
        {
          title: "WebMD",
          icon: "$webmd",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginWebMD") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "WebMDImport",
            },
          ],
        },
        {
          title: "Healthcasts",
          icon: "$healthcasts",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginHealthcasts") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "HealthcastsImport",
            },
          ],
        },
        {
          title: "Haymarket",
          icon: "$haymarket",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginHaymarket") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "HaymarketImport",
            },
          ],
        },
        {
          title: "Doceree",
          icon: "$doceree",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginDoceree") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "DocereeImport",
            },
          ],
        },
        {
          title: "eHealthcare Solutions",
          icon: "$ehs",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginEHealthcareSolutions") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "EHealthcareSolutionsImport",
            },
          ],
        },
        {
          title: "Veradigm",
          icon: "$veradigm",
          canView: this.$store.getters['user/activePlugins']?.includes("AddPluginVeradigm") && this.$store.getters['user/hasWritePermission'],
          items: [
            {
              title: "Import",
              path: "VeradigmImport",
            },
          ],
        },
        {
          title: "NPI Match",
          path: 'NpiMatch',
          icon: 'mdi-magnify',
        },
        {
          title: "Integrations",
          icon: mdiApplicationCogOutline,
          items: [
            {
              title: "API Tokens",
              path: "ApiTokens",
              canView: this.$store.getters['user/hasWritePermission'],
            },
            {
              title: "Plugins",
              path: "Plugins",
            }
          ],
        },
        {
          title: "Pulse AI",
          icon: "$pulseAI",
          canView: this.$store.getters['user/isPulseAdmin'],
          items: [
            {
              title: "Manage Prompts",
              path: "AIPromptList",
            },
          ],
        },
      ];
    },
    nav_items: {
      get() {
        if (this.$route.name === "PageNotFound") {
          // 404 not found then no items
          return [];
        }

        if (this.$store.getters["user/isPulseAdmin"]) {
          if (Object.keys(this.$store.getters["user/account"]).length === 0 || this.$store.getters["user/account"].accountId === undefined || this.$route.name === "Accounts" || this.$route.name === 'NewAccount' || this.$route.name === 'ManagerUserList' || this.$route.name === 'ManagerUser') {
            return [...this.accounts_nav, ...this.admin_accounts_nav];
          } else {
            this.custom_nav_labels();
            return [...this.regular_nav];
          }
        } else {
          if (Object.keys(this.$store.getters["user/account"]).length === 0 || this.$store.getters["user/account"].accountId === undefined || this.$route.name === "Accounts" || this.$route.name === 'NewAccount' || this.$route.name === 'ManagerUserList' || this.$route.name === 'ManagerUser') {
            return this.accounts_nav;
          } else {
            this.custom_nav_labels();
            return this.regular_nav;
          }
        }
      },
   },
  },
  watch: {
    drawer: function () {
      this.$emit("toggle-side-menu", this.drawer);
    },
  },
  async created() {
    this.subscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "toggleMenu") {
        this.drawer = !this.drawer;
      }
      if (mutation.type === "user/update_default_dashboard") {
        this.regular_nav[0].items[0].params.id = this.$store.getters["user/defaultDashboard"]
      }
    });
    this.$emit("toggle-side-menu", this.drawer);
  },
  destroyed() {
    this.subscription();
  },
  methods: {
    custom_nav_labels: function() {
      let i;
      let accountType = this.$store.getters["user/account"].accountType;
      for (i = 0; i < this.regular_nav.length; i++) {
        if (this.regular_nav[i]?.id == "accountType") {
          this.regular_nav[i].title = accountType === "HCP" ? "My HCP's" : "My Patients";
        }
      }
    },
  },
};
</script>

<style lang="scss">
// hide scroll
.pulse-navigation > .v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
.pulse-navigation > .v-navigation-drawer__content {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.desktop-logo {
  position: relative;
}

.desktop-logo .normal {
  position: absolute;
  top: -25px;
  left: 10px;
  border-radius: 100%;
  padding: 6px 10px;
  background-color: #173d66;
  z-index: 11;
  width: 25%;
  overflow-x: hidden;
  white-space: nowrap;

  .main-pulse, .pulse {
    display: inline-block;
  }

  .pulse {
    margin-left: 20px;
  }
}

.desktop-logo .normal:hover {
  border-radius: 20%;
  width: auto;

  .pulse {
    margin-left: 10px;
  }

  ~ .cloud-img {
    display: none;
  }
}

.desktop-logo .cloud-img {
  position: absolute;
  top: -20px;
  left: 60px;
  transition: .2s ease;
}
@media print {
  .pulse-navigation {
    display: none !important;
  }
}
</style>
